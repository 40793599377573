
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import { templatesCategoryOptions } from '@/definitions/_general/_data/optionsList'

export default defineComponent({
  name: 'TemplatesDeleteCategoryModal',
  components: { TmColoredUl, TmFormLine, TmButton, TmModal },
  props: {
    categoryName: {
      type: String,
    },
  },
  setup() {
    const moveTo = ref(templatesCategoryOptions[2])

    return {
      templatesCategoryOptions,
      moveTo,
    }
  },
})
